import React from 'react'
import MainHeader from './MainHeader'
import Header from './Header'
import Banner from './Banner'
import Form from './Form'
import Footer from './Footer'
import Admin from './Admin'
import Login from './Login'

const CertificatePage = ()=>{
    return(
        <>
        <MainHeader/>
        <Header/>
        <Banner/>
        <Form/>
        {/* <Admin/>
        <Login/> */}
        <Footer/>
        </>
    )
}

export default CertificatePage
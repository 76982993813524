import React, { useState } from 'react';
import { Navbar, Nav, Offcanvas } from 'react-bootstrap';
import './Header.css'; // Your existing CSS, modified for new components

const Header = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Navbar bg="light" expand="lg" className="header-navbar">
                <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow} />
                <Navbar.Brand href="https://dylanacademy.in" className="ms-2">Dylan Academy</Navbar.Brand>
                <Navbar.Collapse id="offcanvasNavbar">
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                        <Nav.Link href="https://dylanacademy.in/about/">About Us</Nav.Link>
                        <Nav.Link href="https://dylanacademy.in/courses/">Courses</Nav.Link>
                        <Nav.Link href="https://dylanacademy.in/our-glance/">Our Glance</Nav.Link>
                        <Nav.Link href="https://dylanacademy.in/certificates/">Certificates</Nav.Link>
                        <Nav.Link href="https://dylanacademy.in/contact/">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Offcanvas show={show} onHide={handleClose} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Dylan Academy</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="flex-column">
                        <Nav.Link href="https://dylanacademy.in/about/">About Us</Nav.Link>
                        <Nav.Link href="https://dylanacademy.in/courses/">Courses</Nav.Link>
                        <Nav.Link href="https://dylanacademy.in/our-glance/">Our Glance</Nav.Link>
                        <Nav.Link href="https://dylanacademy.in/certificates/">Certificates</Nav.Link>
                        <Nav.Link href="https://dylanacademy.in/contact/">Contact</Nav.Link>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default Header;

import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import Student from "./Student/Student";
import "../App.css";

const VerificationForm = () => {
  const [enrollmentNo, setEnrollmentNo] = useState("");
  const [student, setStudent] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setStudent(true);
  };

  const handleInputChange = (event) => {
    setStudent(false);
    setEnrollmentNo(event.target.value);
  };

  return (
    <Container>
      <Row className="justify-content-md-center mb-lg-5 mb-3">
        <Col lg={6} md={8}>
          <h1 className="text-center mt-4">Verify Student Record</h1>
          <Form onSubmit={handleSubmit} className="mt-4">
            <Form.Group controlId="formEnrollmentNo">
              <Form.Label>Enrollment Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Enrollment No."
                value={enrollmentNo}
                onChange={handleInputChange}
                className="mb-3 p-lg-2 px-lg-3 px-2 rounded-pill"
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="w-100 p-lg-2 p-1 custom-button"
            >
              Submit
            </Button>
          </Form>
          {student && <Student enrollmentNo={enrollmentNo} />}
        </Col>
      </Row>
    </Container>
  );
};

export default VerificationForm;

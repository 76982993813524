import React from 'react'
import { FaFacebookF,FaWhatsapp,FaInstagram  } from "react-icons/fa";
import { BsTelephoneFill } from "react-icons/bs";
import { IoMdPhonePortrait } from "react-icons/io";
import { IoMail } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";

import { BsClockFill } from "react-icons/bs";



const Footer = ()=>{
    return(
        <>
        <div className='row bg-dark text-white'>
        <div className='col-lg-1'></div>

            <div className='col-lg-2 p-3' >
                <div style={{textAlign:'left'}} className='text-white'>
                <h2 style={{fontSize:'28px'}}>Menus</h2>
                <div className=' mt-3'>
                <a href='https://dylanacademy.in'  className='text-white'  style={{textDecoration:'none'}}>Home</a><br/>

                </div>
                <div className=' mt-3'>

                 <a  href='https://dylanacademy.in/about/' className='text-white mt-3' style={{textDecoration:'none'}}>About Us</a><br/>
                 </div>
                 <div className=' mt-3'>

                 <a href='https://dylanacademy.in/courses/' className='text-white mt-3' style={{textDecoration:'none'}}>Courses</a><br/>
                 </div>
                 <div className=' mt-3'>

                 <a href='https://dylanacademy.in/our-glance/' className='text-white mt-3' style={{textDecoration:'none'}}>Our Glance</a><br/>
                 </div>
                 <div className=' mt-3'>

                 <a href='https://dylanacademy.in/certificates/' className='text-white mt-3' style={{textDecoration:'none'}}>Certificates</a><br/>
                 </div>
                 <div className=' mt-3'>

                 <a href='https://dylanacademy.in/contact/' className='text-white mt-3' style={{textDecoration:'none'}}>Contact</a><br/>
                 </div>
                </div>
            </div>
            <div className='col-lg-1'></div>
            <div className='col-lg-3 p-3' >
                <div style={{textAlign:'left'}} className='text-white'>
                <h2 className='mt-2' style={{fontSize:'18px'}}>Contact</h2>
                <div className=' mt-3'>
                <a href='/' className='text-white'  style={{textDecoration:'none'}}><BsTelephoneFill />&nbsp;&nbsp;+91 98152-52279</a><br/>

                </div>
                <div className=' mt-3'>

                 <a href='/' className='text-white mt-3' style={{textDecoration:'none'}}><IoMdPhonePortrait />&nbsp;&nbsp;+91 88727-76466</a><br/>
                 </div>
                 <div className=' mt-3'>

                 <a href='/' className='text-white mt-3' style={{textDecoration:'none'}}><IoMail />&nbsp;&nbsp;dlan@dylanacademy.in</a><br/>
                 </div>
                 <div className=' mt-3'>

                 <a href='/' className='text-white mt-3' style={{textDecoration:'none'}}><BsClockFill />&nbsp;&nbsp;
Monday to Saturday: 7.00am - 10.00am <p className='mx-4'> &nbsp;to 3.00pm - 8pm</p></a><br/>
                 </div>
                 <div style={{marginTop:'-20px'}}>

                 <a href='/' className='text-white mt-3' style={{textDecoration:'none'}}><IoLocationSharp />
&nbsp;&nbsp;227, St. No. 18, Near Saraswati School, Bank &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Colony, Haibowal Kalan, Ludhiana</a><br/>
                 </div>
                
                </div>
            </div>
            <div className='col-lg-3 p-3' >
                <div style={{textAlign:'left'}} className='text-white'>
                <h2 className='mt-2' style={{fontSize:'18px'}}>Follow Us</h2>
                <div className=' mt-3'>
                <a href='/' className='text-white'  style={{textDecoration:'none'}}><FaFacebookF style={{fontSize:'20px'}}/></a>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;<FaInstagram style={{fontSize:'20px'}}/>
</span><span>&nbsp;&nbsp;&nbsp;&nbsp;<FaWhatsapp style={{fontSize:'20px'}}/>
</span>

                </div>
            
                
                </div>
            </div>


        </div>
        </>
    )
}

export default Footer
import React from "react";
import { FaFacebookF, FaWhatsapp, FaInstagram } from "react-icons/fa";
import { BsTelephoneFill } from "react-icons/bs";
import { IoMdPhonePortrait } from "react-icons/io";
import { IoMail } from "react-icons/io5";

const MainHeader = () => {
  return (
    <div className="row bg-dark d-lg-flex d-none text-white p-3 align-items-center">
      <div className="col-lg-6 col-md-6 col-sm-12">
        <BsTelephoneFill />
        &nbsp; +91 98152-52279 &nbsp;&nbsp;
        <IoMdPhonePortrait />
        &nbsp; +91 88727-76466 &nbsp;&nbsp;
        <IoMail />
        &nbsp; dlan@dylanacademy.in
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 text-right">
        <a href="/" className="header-icon">
          <FaFacebookF />
        </a>
        <a href="/" className="header-icon">
          <FaInstagram />
        </a>
        <a href="/" className="header-icon">
          <FaWhatsapp />
        </a>
      </div>
    </div>
  );
};

export default MainHeader;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  let navigate = useNavigate();
  const handleLogin = async (event) => {
    event.preventDefault();
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "email": username,
      "password": password
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch("https://certification.pythonanywhere.com/api/login/", requestOptions)
      .then(response => response.json())
      .then(result => {
        debugger
        localStorage.setItem('admin','true')
        if(result?.token){
          navigate('/admin');
        }else{
          alert("wrong id passwordd")
        }
       
      })
      .catch(error => console.log('error', error));
    
  };

  return (
    <div>
        <div className='row'>
            <div className='col-lg-4'></div>
            <div className='col-lg-4'>
            <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <label>
          Username: </label>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} className='w-100' />

        <label>
          Password: </label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className='w-100'/>
       
        <button type="submit" className='w-100 mt-3 p-2 mb-5' style={{border:'1px solid #a64929',backgroundColor:'#a64929',color:'white',borderRadius:'10px'}}>Log In</button>
      </form>
            </div>

        </div>
   
    </div>
  );
}

export default Login;
import React from 'react';
import {  Routes, Route, Form } from 'react-router-dom';
import Admin from './components/Admin';
import CertificatePage from './components/CertificatePage';
import Login from './components/Login';

const App = () => {
  return (
    <div>
       
      <Routes>
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/" element={<CertificatePage />} />
        <Route exact path="/form" element={<Form/>} />
        <Route exact path="/login" element={<Login/>} />

      </Routes>
    
    </div>
   
  );
};

export default App;
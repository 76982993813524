import React, { useEffect, useState } from "react";
import "./Admin.css";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
function Admin() {
  // Initialize the grades array with 10 empty rows
  let navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("admin")) {
      navigate("/login");
    }
  }, []);
  const initialGrades = new Array(10).fill(null).map(() => ({
    subject: "",
    marks_obtained: "",
    minimum_marks: "",
    maximum_marks: "",
  }));

  const [formData, setFormData] = useState({
    student_id: "",
    student_name: "",
    fathers_name: "",
    course_duration: "",
    from_date: "",
    to_date: "",
    course_durationDuration: "",
    certificate_issue_date: "",
    grades: initialGrades,
  });
  const handleInputChange1 = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleInputChange = (e, index) => {
    // Update the grades array without mutating the state directly
    const updatedGrades = formData.grades.map((item, i) =>
      index === i ? { ...item, [e.target.name]: e.target.value } : item
    );

    setFormData({
      ...formData,
      grades: updatedGrades,
    });
  };

  const handleSubmit = async (e) => {
    debugger;
    console.log(formData);
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://certification.pythonanywhere.com/api/certificates/",
        formData
      );
      console.log(response.data);
      setFormData({
        student_id: "",
        student_name: "",
        fathers_name: "",
        course_duration: "",
        from_date: "",
        to_date: "",
        course_durationDuration: "",
        certificate_issue_date: "",
        grades: initialGrades,
      });
      alert("sussfully stored");
      // Handle success response
    } catch (error) {
      console.error(error);
      // Handle error response
    }
  };

  return (
    <Container className="py-lg-5 py-3 bg-light">
      <Row className="justify-content-md-center">
        <Col md={6}>
          <Form onSubmit={handleSubmit}>
            <h1 className="text-center">Submit Student Record</h1>
            {/* Student ID */}
            <Form.Group controlId="formStudentID">
              <Form.Label>Student ID</Form.Label>
              <Form.Control
                type="text"
                name="student_id"
                value={formData.student_id}
                onChange={handleInputChange1}
              />
            </Form.Group>
            <Form.Group controlId="formStudentID">
              <Form.Label> Student Name:</Form.Label>
              <Form.Control
                type="text"
                name="student_name"
                value={formData.student_name}
                onChange={handleInputChange1}
              />
            </Form.Group>
            <Form.Group controlId="formStudentID">
              <Form.Label> Father's Name:</Form.Label>
              <Form.Control
                type="text"
                name="fathers_name"
                value={formData.fathers_name}
                onChange={handleInputChange1}
              />
            </Form.Group>
            <Form.Group controlId="formStudentID">
              <Form.Label>Course :</Form.Label>
              <Form.Control
                type="text"
                name="course_duration"
                value={formData.course_duration}
                onChange={handleInputChange1}
              />
            </Form.Group>
            <Form.Group controlId="formStudentID">
              <Form.Label>From Date:</Form.Label>
              <Form.Control
                type="date"
                name="from_date"
                value={formData.from_date}
                onChange={handleInputChange1}
              />
            </Form.Group>
            <Form.Group controlId="formStudentID">
              <Form.Label> To Date:</Form.Label>
              <Form.Control
                type="date"
                name="to_date"
                value={formData.to_date}
                onChange={handleInputChange1}
              />
            </Form.Group>
            <Form.Group controlId="formStudentID">
              <Form.Label> Course Duration :</Form.Label>
              <Form.Control
                type="text"
                name="course_durationDuration"
                value={formData.course_durationDuration}
                onChange={handleInputChange1}
              />
            </Form.Group>
            <Form.Group controlId="formStudentID">
              <Form.Label> Certificate Issue Date:</Form.Label>
              <Form.Control
                type="date"
                name="certificate_issue_date"
                value={formData.certificate_issue_date}
                onChange={handleInputChange1}
              />
            </Form.Group>
            {/* Other input fields similar to Student ID */}

            {/* Grades */}
            <h2 className="mt-4">Grade Table</h2>
            {formData.grades.map((grade, index) => (
              <Row key={index} className="mb-3">
                <Col>
                  <Form.Control
                    type="text"
                    name="subject"
                    value={grade.subject}
                    onChange={(e) => handleInputChange(e, index)}
                    placeholder="Subject"
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    name="marks_obtained"
                    value={grade.marks_obtained}
                    onChange={(e) => handleInputChange(e, index)}
                    placeholder="Marks Obtained"
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    name="minimum_marks"
                    value={grade.minimum_marks}
                    onChange={(e) => handleInputChange(e, index)}
                    placeholder="Minimum Marks"
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    name="maximum_marks"
                    value={grade.maximum_marks}
                    onChange={(e) => handleInputChange(e, index)}
                    placeholder="maximum Marks"
                  />
                </Col>
              </Row>
            ))}

            <Button type="submit" style={customButton} className="w-100 mt-3 ">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

const customButton = {
  backgroundColor: "#a64929",
};

const formContainer = {
  maxWidth: "400px",
  margin: "auto",
  padding: "20px",
  border: "1px solid #ccc",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
};

const labelStyle = {
  display: "block",
  margin: "10px 0",
};

// Internal CSS
const gradeTableContainer = {
  maxWidth: "600px",
  margin: "auto",
  padding: "20px",
  border: "1px solid #ccc",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  marginTop: "10px",
};

const gradeRow = {
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
};

const inputStyle = {
  marginRight: "10px",
};

const submitButton = {
  marginTop: "20px",
  padding: "10px",
  backgroundColor: "#4CAF50",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};
export default Admin;

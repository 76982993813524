import React from 'react';
import banner from './images/banner.jpg';
import './Banner.css'; 

const Banner = () => {
  return (
    <div className='banner-container'>
      <img src={banner} alt='Banner' className='banner-image' />
      <div className='banner-text'>
        <span className='banner-subtitle'>Know us better</span>
        <h1 className='banner-title'>Certificate Verification</h1>
      </div>
    </div>
  );
};
 
export default Banner;

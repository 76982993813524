import React, { useEffect, useState } from "react";
import "./Student.css"; // Updated CSS file
import { Table, Button, Row, Col } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
const Student = (props) => {
  const [certificate, setCertificate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showGraph, setShowGraph] = useState(false);
  useEffect(() => {
    fetch("https://certification.pythonanywhere.com/api/certificates/" + props?.enrollmentNo + "/")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCertificate(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
      });
  }, [props?.enrollmentNo]);


  const downloadPdfDocument = () => {
    const input = document.getElementById('certificateDetails');
    
    // Check if the element exists
    if (input) {
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'PNG', 0, 0);
                pdf.save("dylanacademy-certificate.pdf");
            })
            .catch(err => {
                console.error("Error capturing the element: ", err);
            });
    } else {
        console.error("Element to capture not found in the DOM");
    }
};
const toggleGraph = () => {
  setShowGraph(!showGraph);
};

const gradesChartData = {
  labels: certificate?.grades.map(grade => grade.subject),
  datasets: [{
      label: 'Marks Obtained',
      data: certificate?.grades.map(grade => grade.marks_obtained),
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1
  }]
};
  if (isLoading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className="certificate-container">
    <Row className="button-row mt-3">
                <Col xs={6} className="text-start">
                    <Button variant="primary" onClick={downloadPdfDocument}>
                        Download 
                    </Button>
                </Col>
                <Col xs={6} className="text-end">
                    <Button variant="secondary" onClick={toggleGraph}>
                        {showGraph ? 'Hide' : 'Show'} Grades Graph
                    </Button>
                </Col>
            </Row>
            {showGraph && (
                <div className="mt-4">
                    <Bar data={gradesChartData} />
                </div>
            )}
      {certificate && (
        <div className="certificate-details" id="certificateDetails">
         <Card className="certificate-details-card">
      <Card.Body>
        <Card.Title>Certificate Details</Card.Title>
        <Card.Text><strong>Student ID:</strong> {certificate.student_id}</Card.Text>
        <Card.Text><strong>Student Name:</strong> {certificate.student_name}</Card.Text>
        <Card.Text><strong>Father's Name:</strong> {certificate.fathers_name}</Card.Text>
        <Card.Text><strong>Course Duration:</strong> {certificate.course_duration}</Card.Text>
        <Card.Text><strong>From Date:</strong> {certificate.from_date}</Card.Text>
        <Card.Text><strong>To Date:</strong> {certificate.to_date}</Card.Text>
        <Card.Text><strong>Certificate Issue Date:</strong> {certificate.certificate_issue_date}</Card.Text>
      </Card.Body>
    </Card>
          <h3>Grades</h3>
         <Table striped bordered hover responsive className="grades-table">
      <thead>
        <tr>
          <th>Subject</th>
          <th>Marks Obtained</th>
          <th>Minimum Marks</th>
          <th>Maximum Marks</th>
        </tr>
      </thead>
      <tbody>
        {certificate.grades.map((grade, index) => (
          <tr key={index}>
            <td>{grade.subject}</td>
            <td>{grade.marks_obtained}</td>
            <td>{grade.minimum_marks}</td>
            <td>{grade.maximum_marks || "N/A"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
        </div>
      )}
    </div>
  );
};

export default Student;
